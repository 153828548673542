import { useContext } from "react";
import { AppLayoutContext } from "routes/app_layout";
import { ShareLayoutContext } from "routes/share_layout";

const useMessage = () => {
  const appLayoutContext = useContext(AppLayoutContext, {});
  const shareLayoutContext = useContext(ShareLayoutContext, {});
  return appLayoutContext?.messageApi || shareLayoutContext?.messageApi;
};

export default useMessage;
