import { DeleteOutlined } from "@ant-design/icons";
import { Button, List, Spin } from "antd";
import DeleteSessionModal from "components/app/profile/delete_experiment_modal";
import { PAGE_SIZE } from "components/app/profile/profile_util";
import update from "immutability-helper";
import moment from "moment";
import { useExperimentsQuery } from "queries";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ExperimentListItem = ({ experiment, removeExperiment }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <List.Item
      style={{ paddingTop: 6, paddingBottom: 6 }}
      actions={[
        <Button
          danger
          type="text"
          icon={<DeleteOutlined />}
          onClick={() => setDeleteModalOpen(true)}
        />,
      ]}
    >
      <div className="flex flex-col">
        <div>
          <Button
            type="link"
            className="text-sm px-0 py-0 text-inherit"
            style={{
              borderWidth: "0px 0px 0px 0px",
            }}
            onClick={() => navigate(`/app/experiments/${experiment.id}`)}
          >
            {experiment.name}
          </Button>
        </div>
        <div className="font-subdued text-gray-400">
          {moment(experiment.timestamp).format("MMMM Do, h:mm a")}
        </div>
      </div>
      <DeleteSessionModal
        experiment={experiment}
        removeExperiment={removeExperiment}
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
      />
    </List.Item>
  );
};

const ExperimentsTab = () => {
  const [experiments, setExperiments] = useState([]);
  const { data, isLoading } = useExperimentsQuery();

  useEffect(() => {
    data && setExperiments(data);
  }, [data, setExperiments]);

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <List
      size="large"
      dataSource={experiments}
      pagination={{
        pageSize: PAGE_SIZE,
        showSizeChanger: false,
      }}
      renderItem={(item, index) => (
        <ExperimentListItem
          experiment={item}
          removeExperiment={() =>
            setExperiments(update(experiments, { $splice: [[index, 1]] }))
          }
        />
      )}
    />
  );
};

export default ExperimentsTab;
