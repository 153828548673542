import { authAxios } from "http/axios";
import { useMemo } from "react";
import { useQuery } from "react-query";
import useAuthStore from "store/auth_store";

export const useAppQuery = ({ url, fetchInit = {}, reactQueryOptions }) => {
  const accessToken = useAuthStore((state) => state.accessToken);
  const fetch = useMemo(() => {
    return async () => {
      const response = await authAxios.get(url, fetchInit);
      return response.data;
    };
  }, [url, fetchInit]);

  return useQuery([url, accessToken], fetch, {
    ...reactQueryOptions,
    refetchOnWindowFocus: false,
  });
};

export function useExperimentsQuery() {
  return useAppQuery({
    url: `/experiments/`,
    reactQueryOptions: { staleTime: 60000 },
  });
}

export function useUserProfileQuery() {
  return useAppQuery({
    url: "/auth/user/",
    reactQueryOptions: { staleTime: "Infinity" },
  });
}
