import { CopyOutlined } from "@ant-design/icons";
import { Button, Divider, Modal } from "antd";
import useExperimentContext from "hooks/use_experiment_context";
import useMessage from "hooks/use_message";
import { authAxios } from "http/axios";
import { useState } from "react";

const DEFAULT_BUTTON_TEXT = "Copy Link";

const ShareExperimentModal = ({ modalOpen, setModalOpen }) => {
  const [buttonText, setButtonText] = useState(DEFAULT_BUTTON_TEXT);
  const { experiment } = useExperimentContext();
  const message = useMessage();

  const onClick = async () => {
    setButtonText("Copying");
    const { data } = await authAxios.post(`share/${experiment.id}/`);

    navigator.clipboard.writeText(`${window.location.origin}/share/${data.id}`);
    setButtonText(DEFAULT_BUTTON_TEXT);
    setModalOpen(false);
    message.success({ content: "Shareable link copied!", duration: 5 });
  };

  return (
    <Modal
      title="Share experiment"
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      width={500}
      transitionName=""
      footer={[
        <Button
          type="primary"
          icon={<CopyOutlined />}
          loading={buttonText !== DEFAULT_BUTTON_TEXT}
          onClick={onClick}
        >
          {buttonText}
        </Button>,
      ]}
    >
      <Divider />
      <div>
        Create a shareable link. Changes you make after creating your link won't
        be shared. Anyone with the URL will be able to view the shared
        experiment.
      </div>
    </Modal>
  );
};

export default ShareExperimentModal;
