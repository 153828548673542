import AddColumnTransform from "components/app/transforms/transform_types/add_column_transform";
import RemoveColumnTransform from "components/app/transforms/transform_types/remove_column_transform";
import RenameColumnTransform from "components/app/transforms/transform_types/rename_column_transform";

const TRANSFORMERS = {
  add_column: AddColumnTransform,
  remove_column: RemoveColumnTransform,
  rename_column: RenameColumnTransform,
};

const getTransformer = (transform) => {
  if (!(transform.type in TRANSFORMERS)) {
    throw Error("Missing transformer for transform: " + transform);
  }
  return TRANSFORMERS[transform.type];
};

export const getReadableMessage = (transform) => {
  const transformer = getTransformer(transform);
  return transformer.getReadableMessage(transform);
};

export default getTransformer;
