import { BarChartOutlined, PlusOutlined } from "@ant-design/icons";
import { Layout, Menu, Spin, message, theme } from "antd";
import UserButton from "components/app/user_button";
import Logo from "components/home/logo";
import { useExperimentsQuery } from "queries";
import { createContext } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { HEADER_HEIGHT } from "routes/app_layout_constants";
import useAuthStore from "store/auth_store";

export const AppLayoutContext = createContext();

// We have to use the old Menu.Item syntax in order to get access to the menu item div to set the marginRight style here
// The new object based API doesn't let us do that, unless we override the CSS stylesheets
const buildMenuItems = (experiments, isLoading, pathname) => {
  const experimentItems =
    !isLoading &&
    experiments.map((experiment) => (
      <Menu.Item key={experiment.id}>{experiment.name}</Menu.Item>
    ));

  return (
    <>
      {/* https://stackoverflow.com/questions/32551291/in-css-flexbox-why-are-there-no-justify-items-and-justify-self-properties */}
      <Menu.Item key="home" style={{ marginRight: "auto", paddingTop: "8px" }}>
        <Logo />
      </Menu.Item>
      {/* only show new session if not on home page */}
      {pathname !== "/app" && (
        <Menu.Item key="create-experiment" icon={<PlusOutlined />}>
          New experiment
        </Menu.Item>
      )}
      <Menu.SubMenu
        key="experiments"
        title="Experiments"
        icon={<BarChartOutlined />}
      >
        {isLoading ? (
          <Spin>
            <div className="w-10 h-10" />
          </Spin>
        ) : (
          experimentItems
        )}
      </Menu.SubMenu>
    </>
  );
};

const AppLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const logout = useAuthStore((state) => state.logout);
  const navigate = useNavigate();
  const navigation = useNavigation();
  const { pathname } = useLocation();
  const { data: experiments, isLoading } = useExperimentsQuery();
  const [messageApi, contextHolder] = message.useMessage();

  const menuOnClick = async (item) => {
    if (item.key === "home") {
      navigate("/");
    } else if (item.key === "create-experiment") {
      navigate("/");
    } else if (item.key === "logout") {
      logout();
    } else if (item.keyPath.includes("experiments")) {
      navigate(`experiments/${item.keyPath[0]}`);
    }
  };

  return (
    <AppLayoutContext.Provider
      value={{
        messageApi: messageApi,
      }}
    >
      {contextHolder}
      <Layout style={{ minHeight: "100vh" }} className="bg-white">
        <Layout.Header
          style={{
            display: "flex",
            width: "100%",
            paddingInlineStart: 0,
            paddingInlineEnd: 0,
            height: HEADER_HEIGHT,
            background: colorBgContainer,
            position: "sticky",
            top: 0,
            zIndex: 50,
          }}
        >
          <Menu
            theme="light"
            mode="horizontal"
            onClick={menuOnClick}
            style={{
              width: "100%",
              height: HEADER_HEIGHT,
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            {buildMenuItems(experiments, isLoading, pathname)}
          </Menu>
          <div
            className="flex pb-0 mb-0 pl-2 pr-4 pt-3.5"
            style={{
              borderBottomColor: "rgba(5, 5, 5, 0.06)",
              borderBottomStyle: "solid",
              borderBottomWidth: "1px",
            }}
          >
            <UserButton />
          </div>
        </Layout.Header>
        <div
          id="app-main"
          className={`bg-white ${
            navigation.state === "loading" ? "loading" : ""
          }`}
        >
          <Outlet />
        </div>
      </Layout>
    </AppLayoutContext.Provider>
  );
};

export default AppLayout;
