/**
 * Manually generated by running:
 *   - json.dumps(VizSpec.model_json_schema())
 * in a deepdive-server shell (python manage.py shell)
 */
export const VizSpecSchema = {
  $defs: {
    Binner: {
      properties: {
        binner_type: {
          enum: ["datetime", "numeric"],
          title: "Binner Type",
          type: "string",
        },
        time_unit: {
          anyOf: [
            {
              enum: [
                "second",
                "minute",
                "hour",
                "hour_of_day",
                "day",
                "day_of_week",
                "day_of_month",
                "week",
                "week_of_year",
                "week_of_year_long",
                "month",
                "month_of_year",
                "year",
              ],
              type: "string",
            },
            { type: "null" },
          ],
          title: "Time Unit",
        },
        scale: {
          anyOf: [
            { maximum: 100, minimum: 0, type: "integer" },
            { type: "null" },
          ],
          default: null,
          title: "Scale",
        },
      },
      required: ["binner_type", "time_unit"],
      title: "Binner",
      type: "object",
    },
    Breakdown: {
      properties: {
        name: { title: "Name", type: "string" },
        alias: {
          anyOf: [{ type: "string" }, { type: "null" }],
          default: null,
          title: "Alias",
        },
        unparsed: {
          anyOf: [{ type: "boolean" }, { type: "null" }],
          default: false,
          title: "Unparsed",
        },
      },
      required: ["name"],
      title: "Breakdown",
      type: "object",
    },
    Filter: {
      properties: {
        name: { title: "Name", type: "string" },
        filter_type: {
          enum: ["comparison", "numeric", "like", "complex"],
          title: "Filter Type",
          type: "string",
        },
        expression: {
          anyOf: [{ type: "string" }, { type: "null" }],
          default: null,
          title: "Expression",
        },
        subfilters: {
          anyOf: [
            {
              maxItems: 2,
              minItems: 2,
              prefixItems: [
                { $ref: "#/$defs/Filter" },
                { $ref: "#/$defs/Filter" },
              ],
              type: "array",
            },
            { type: "null" },
          ],
          default: null,
          title: "Subfilters",
        },
        domain: {
          anyOf: [
            {
              maxItems: 2,
              minItems: 2,
              prefixItems: [
                {
                  anyOf: [
                    { type: "integer" },
                    { type: "number" },
                    { type: "string" },
                    { type: "null" },
                  ],
                },
                {
                  anyOf: [
                    { type: "integer" },
                    { type: "number" },
                    { type: "string" },
                    { type: "null" },
                  ],
                },
              ],
              type: "array",
            },
            { type: "null" },
          ],
          default: null,
          title: "Domain",
        },
        values: {
          anyOf: [
            {
              items: {
                anyOf: [
                  { type: "integer" },
                  { type: "number" },
                  { type: "string" },
                ],
              },
              type: "array",
            },
            { type: "null" },
          ],
          default: null,
          title: "Values",
        },
        negate: { default: false, title: "Negate", type: "boolean" },
      },
      required: ["name", "filter_type"],
      title: "Filter",
      type: "object",
    },
    SortBy: {
      properties: {
        name: { title: "Name", type: "string" },
        direction: {
          anyOf: [{ enum: ["asc", "desc"], type: "string" }, { type: "null" }],
          default: "asc",
          title: "Direction",
        },
        unparsed: {
          anyOf: [{ type: "boolean" }, { type: "null" }],
          default: false,
          title: "Unparsed",
        },
      },
      required: ["name"],
      title: "SortBy",
      type: "object",
    },
    VizType: {
      enum: ["bar", "line", "area", "pie", "table", "scatter"],
      title: "VizType",
      type: "string",
    },
    XAxis: {
      properties: {
        name: { title: "Name", type: "string" },
        alias: {
          anyOf: [{ type: "string" }, { type: "null" }],
          default: null,
          title: "Alias",
        },
        domain: {
          anyOf: [
            {
              maxItems: 2,
              minItems: 2,
              prefixItems: [
                {
                  anyOf: [
                    { type: "integer" },
                    { type: "number" },
                    { type: "string" },
                    { type: "null" },
                  ],
                },
                {
                  anyOf: [
                    { type: "integer" },
                    { type: "number" },
                    { type: "string" },
                    { type: "null" },
                  ],
                },
              ],
              type: "array",
            },
            { type: "null" },
          ],
          default: null,
          title: "Domain",
        },
        binner: {
          anyOf: [{ $ref: "#/$defs/Binner" }, { type: "null" }],
          default: null,
        },
        unparsed: {
          anyOf: [{ type: "boolean" }, { type: "null" }],
          default: false,
          title: "Unparsed",
        },
      },
      required: ["name"],
      title: "XAxis",
      type: "object",
    },
    YAxis: {
      properties: {
        name: { title: "Name", type: "string" },
        alias: {
          anyOf: [{ type: "string" }, { type: "null" }],
          default: null,
          title: "Alias",
        },
        aggregation: {
          anyOf: [
            { enum: ["COUNT", "SUM", "AVG", "MIN", "MAX"], type: "string" },
            { type: "null" },
          ],
          default: null,
          title: "Aggregation",
        },
        unparsed: {
          anyOf: [{ type: "boolean" }, { type: "null" }],
          default: false,
          title: "Unparsed",
        },
      },
      required: ["name"],
      title: "YAxis",
      type: "object",
    },
  },
  properties: {
    visualization_type: {
      anyOf: [{ $ref: "#/$defs/VizType" }, { type: "null" }],
      default: "bar",
    },
    x_axis: {
      anyOf: [{ $ref: "#/$defs/XAxis" }, { type: "null" }],
      default: null,
    },
    y_axises: {
      default: [],
      items: { $ref: "#/$defs/YAxis" },
      title: "Y Axises",
      type: "array",
    },
    breakdowns: {
      default: [],
      items: { $ref: "#/$defs/Breakdown" },
      title: "Breakdowns",
      type: "array",
    },
    filters: {
      default: [],
      items: { $ref: "#/$defs/Filter" },
      title: "Filters",
      type: "array",
    },
    tables: {
      anyOf: [{ items: { type: "string" }, type: "array" }, { type: "null" }],
      default: [],
      title: "Tables",
    },
    limit: {
      anyOf: [{ type: "integer" }, { type: "null" }],
      default: null,
      title: "Limit",
    },
    sort_by: {
      anyOf: [{ $ref: "#/$defs/SortBy" }, { type: "null" }],
      default: null,
    },
  },
  title: "VizSpec",
  type: "object",
};
