import { Alert, Empty, Spin, Typography } from "antd";
import { validateSpec } from "components/viz/spec_helper";
import { CHART_HEIGHT } from "components/viz/views/chart_helper";
import AreaChartViz from "components/viz/viz_types/area_chart_viz";
import BarChartViz from "components/viz/viz_types/bar_chart_viz";
import LineChartViz from "components/viz/viz_types/line_chart_viz";
import PieChartViz from "components/viz/viz_types/pie_chart_viz";
import ScatterChartViz from "components/viz/viz_types/scatter_chart_viz";
import TableViz from "components/viz/viz_types/table_viz";
import useExperimentContext from "hooks/use_experiment_context";
import { createContext, useEffect, useState } from "react";

const VIZ_TYPES = {
  bar: BarChartViz,
  line: LineChartViz,
  area: AreaChartViz,
  pie: PieChartViz,
  table: TableViz,
  scatter: ScatterChartViz,
};

/**
 * The state management around this is rather involved
 *
 *  - serverSpec is the spec we get back as response from the server (either the /messages/ API or websocket)
 *  - [spec, setSpec] is our local copy of the spec
 *
 * Upon edits to our local copy, we send an update to the server using updateSpec
 * and _mark_ our component as having **sent an update**
 *
 * Once VizWrapper sends an update, it _cannot_ send another
 * Instead, it waits to be remounted by <Response> upon successful receipt of a websocket message
 *
 * It's important that we remount VizWrapper
 * Otherwise, we run into an issue of synchronizing state changes from two sources:
 *  - Our local editor / buttons
 *  - Async response from websocket
 *
 * And we don't know when to trigger an update (e.g, could get into an infinite loop if we call update triggered by the backend)
 *
 * To summarize:
 *   - An instance of VizWrapper can only send _one_ update message
 *   - VizWrapper is _remounted_ upon receiving a server-side update
 */
const useUpdateSpec = (serverSpec, spec, updateSpec) => {
  const [sentUpdate, setSentUpdate] = useState(false);

  useEffect(() => {
    if (sentUpdate) {
      // we already sent our one update, this must be a server-side event
      // do nothing and wait to be remounted
      return;
    }

    const clonedSpec = { ...spec };
    clonedSpec.breakdownValues = undefined;
    const vizSpecString = JSON.stringify(clonedSpec);

    if (serverSpec !== vizSpecString && vizSpecString !== "{}") {
      updateSpec(vizSpecString);
      setSentUpdate(true);
    }
  }, [serverSpec, spec, updateSpec, sentUpdate, setSentUpdate]);
};

const renderView = (viz, spec, setSpec, refreshing) => {
  const { schema, data: rows } = JSON.parse(viz.data || "{}");

  if (refreshing) {
    return (
      <Spin size="large" tip="Loading...">
        <div style={{ height: CHART_HEIGHT + 22, width: CHART_HEIGHT }} />
      </Spin>
    );
  }

  if (viz.error) {
    return (
      <div style={{ height: CHART_HEIGHT + 22, width: "100%" }}>
        <Alert
          className="w-full"
          message={
            <Typography.Title level={5}>
              Failed to execute query
            </Typography.Title>
          }
          description={viz.error}
          showIcon
          type="error"
        />
      </div>
    );
  }

  const vizType = VIZ_TYPES[spec.visualization_type];
  const specError = vizType.validateSpec(spec);
  if (specError) {
    return <Empty description={specError} />;
  } else {
    return vizType.renderView({ schema, rows, spec, setSpec });
  }
};

export const VizWrapperContext = createContext();

const VizView = ({ viz }) => {
  const [spec, setSpec] = useState(JSON.parse(viz.spec || "{}"));

  return renderView(viz, spec, setSpec, false);
};

const VizEdit = ({ viz, updateSpec, refreshing }) => {
  const [spec, setSpec] = useState(JSON.parse(viz.spec || "{}"));
  useUpdateSpec(viz.spec, spec, updateSpec);
  validateSpec(spec);

  const vizType = VIZ_TYPES[spec.visualization_type];
  return (
    <div className="flex flex-row flex-nowrap">
      <div className="flex-auto min-w-0 w-3/4">
        {renderView(viz, spec, setSpec, refreshing)}
      </div>
      <div className="justify-self-end w-1/4 px-6">
        {vizType?.renderEditor({ spec, setSpec })}
      </div>
    </div>
  );
};

const VizWrapper = ({ mode, viz, processQuery }) => {
  const [refreshing, setRefreshing] = useState(false);
  const { previewViz } = useExperimentContext();

  const updateSpec = (vizSpec) => {
    setRefreshing(true);
    previewViz(viz.id, vizSpec);
  };

  return (
    <VizWrapperContext.Provider
      value={{
        mode: mode,
        messageId: viz.id,
        query: viz.sql_query,
        processQuery: processQuery,
      }}
    >
      {"view" === mode ? (
        <VizView viz={viz} />
      ) : (
        <VizEdit viz={viz} updateSpec={updateSpec} refreshing={refreshing} />
      )}
    </VizWrapperContext.Provider>
  );
};

export default VizWrapper;
