import { Divider, Spin } from "antd";
import SheetTable from "components/app/sheet/sheet_table";
import TransformsCard from "components/app/sheet/transforms_card";
import useExperimentContext from "hooks/use_experiment_context";
import { useEffect } from "react";

const SheetEditor = () => {
  const { table, getTable, reloadingData, wsLoading } = useExperimentContext();
  useEffect(() => {
    if (!table && !wsLoading) {
      getTable();
    }
  }, [table, getTable, wsLoading]);

  return (
    <div className="pt-2 flex flex-row w-full">
      <div className="w-4/5">
        {reloadingData ? (
          <Spin size="large" tip="Fetching data...">
            <div className="h-40 w-40" />
          </Spin>
        ) : (
          <SheetTable table={table} />
        )}
      </div>
      <Divider type="vertical" />
      <div className="w-1/5">
        <TransformsCard />
      </div>
    </div>
  );
};

export default SheetEditor;
