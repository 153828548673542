import { Divider } from "antd";
import SaveTransformButton from "components/app/sheet/save_transform_button";
import TransformTypeSelector from "components/app/sheet/transform_type_selector";
import getTransformer from "components/app/transforms/transform_types";
import useExperimentContext from "hooks/use_experiment_context";

const TransformEditor = () => {
  const { activeTransform: transform, setActiveTransform: setTransform } =
    useExperimentContext();
  const transformer = getTransformer(transform);

  return (
    <>
      <TransformTypeSelector
        transform={transform}
        setTransform={setTransform}
      />
      <Divider />
      {transformer.renderEditor({ transform, setTransform })}
      <div className="pb-2" />
      <SaveTransformButton validateTransform={transformer.validateTransform} />
    </>
  );
};

export default TransformEditor;
