import { EditOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import SqlEditorModal from "components/sql/sql_editor_modal";
import { useState } from "react";

const EditorActions = () => {
  const [sqlEditorOpen, setSqlEditorOpen] = useState(false);

  return (
    <>
      <div className="flex flex-row space-x-1">
        <Tooltip title="Edit SQL query">
          <Button
            icon={<EditOutlined />}
            onClick={() => setSqlEditorOpen(true)}
          />
        </Tooltip>
      </div>
      <SqlEditorModal open={sqlEditorOpen} setOpen={setSqlEditorOpen} />
    </>
  );
};

export default EditorActions;
