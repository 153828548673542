import { ExpandAltOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { objectsEqual } from "components/viz/spec_helper";
import update from "immutability-helper";

const ZoomOutButton = ({ spec, setSpec }) => {
  const resetDomain = () => {
    let updatedXAxis = spec.x_axis;
    let updatedFilters = spec.filters;

    if (spec?.x_axis?.name) {
      updatedXAxis = {
        ...spec.x_axis,
        domain: undefined,
      };
    }
    if (spec?.visualization_type === "scatter" && spec?.y_axises.length === 1) {
      const yAxisName = spec.y_axises[0].name;
      const filterIndex = spec.filters.findIndex(
        (filter) =>
          filter.name === yAxisName && filter.filter_type === "numeric",
      );

      if (filterIndex !== -1) {
        updatedFilters = update(updatedFilters, {
          $splice: [[filterIndex, 1]],
        });
      }
    }

    if (
      !objectsEqual(spec.x_axis, updatedXAxis) ||
      !objectsEqual(spec.filters, updatedFilters)
    ) {
      // TODO: not necessary if react batches state updates?
      setSpec({ ...spec, x_axis: updatedXAxis, filters: updatedFilters });
    }
  };

  return (
    <div className="w-18">
      <Button
        icon={<ExpandAltOutlined />}
        size="small"
        type="text"
        onClick={() => resetDomain()}
      />
    </div>
  );
};
export default ZoomOutButton;
