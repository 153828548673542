import DashboardViz from "components/app/dashboard/dashboard_viz";
import GenerateDashboardCard from "components/app/generate_dashboard_card";
import useExperimentContext from "hooks/use_experiment_context";
import AddVizInput from "components/app/dashboard/add_viz_input";

const DashboardGrid = ({ visualizations }) => (
  <div className="pt-2 grid grid-cols-2 gap-4 pb-4">
    {visualizations.map((viz) => (
      <DashboardViz key={viz.id} viz={viz} />
    ))}
  </div>
);

const EmptyReport = () => (
  <div className="pt-32 flex justify-center content-center w-full">
    <div className="flex-col w-1/3">
      <GenerateDashboardCard />
    </div>
  </div>
);

const DashboardEditor = () => {
  const { visualizations } = useExperimentContext();

  return (
    <>
      {visualizations.length ? (
        <DashboardGrid visualizations={visualizations} />
      ) : (
        <EmptyReport />
      )}
      <div className="pb-12" />
      <AddVizInput />
    </>
  );
};

export default DashboardEditor;
