import { Modal } from "antd";
import useMessage from "hooks/use_message";
import { authAxios } from "http/axios";
import { useState } from "react";

const DeleteExperimentModal = ({
  experiment,
  removeExperiment,
  modalOpen,
  setModalOpen,
}) => {
  const [loading, setLoading] = useState(false);
  const message = useMessage();

  const onDelete = () => {
    setLoading(true);
    authAxios
      .delete(`/experiments/${experiment.id}/`)
      .then(() => {
        message.success(`Experiment ${experiment.name} deleted`);
      })
      .finally(() => {
        removeExperiment();
        setLoading(false);
        setModalOpen(false);
      });
  };

  return (
    <Modal
      title="Delete experiment"
      transitionName=""
      open={modalOpen}
      onOk={onDelete}
      onCancel={() => setModalOpen(false)}
      okText="Delete"
      okType="danger"
      confirmLoading={loading}
      width={400}
    >
      Are you sure you want to delete{" "}
      <span className="font-bold">{experiment.name}?</span> This action is
      permanent and irreversible.
    </Modal>
  );
};

export default DeleteExperimentModal;
