import { ConfigProvider, theme } from "antd";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import About from "routes/about";
import Activation from "routes/activation";
import AppLayout from "routes/app_layout";
import CreateExperiment from "routes/create_experiment";
import Error from "routes/error";
import Experiment, { loader as experimentLoader } from "routes/experiment";
import FAQ from "routes/faq";
import Home from "routes/home";
import HomeLayout from "routes/home_layout";
import Login from "routes/login";
import NotFound from "routes/not_found";
import Privacy from "routes/privacy";
import Profile from "routes/profile";
import { RecognizedRoute } from "routes/recognized";
import ShareLayout from "routes/share_layout";
import SharedExperiment, {
  loader as sharedExperimentLoader,
} from "routes/shared_experiment";
import Signup from "routes/signup";
import SocialAuth from "routes/social_auth";
import { UnrecognizedRoute } from "routes/unrecognized";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient();
const router = createBrowserRouter([
  {
    element: <UnrecognizedRoute />,
    errorElement: <Error />,
    children: [
      {
        element: <HomeLayout />,
        children: [
          {
            path: "/",
            element: <Home />,
          },
          {
            path: "/about",
            element: <About />,
          },
          {
            path: "/faq",
            element: <FAQ />,
          },
          {
            path: "/privacy",
            element: <Privacy />,
          },
        ],
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/:provider/callback",
        element: <SocialAuth />,
      },
    ],
  },
  {
    element: <RecognizedRoute />,
    errorElement: <Error />,
    children: [
      {
        path: "/app",
        element: <AppLayout />,
        children: [
          {
            element: <CreateExperiment />,
            index: true,
          },
          {
            path: "experiments/:id",
            element: <Experiment />,
            loader: experimentLoader,
          },
          {
            path: "profile",
            element: <Profile />,
          },
        ],
      },
    ],
  },
  {
    element: <ShareLayout />,
    errorElement: <Error />,
    children: [
      {
        path: "share/:id",
        element: <SharedExperiment />,
        loader: sharedExperimentLoader,
      },
    ],
  },
  {
    path: "/activate/:key",
    element: <Activation />,
    errorElement: <Error />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        algorithm: theme.compactAlgorithm,
        token: {},
        components: {
          Tabs: {
            horizontalItemPadding: "0 0 4px 0",
          },
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ConfigProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
