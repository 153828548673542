import { Card, Tabs } from "antd";
import ExperimentsTab from "components/app/profile/experiments_tab";
import ProfileTab from "components/app/profile/profile_tab";
import { useNavigation } from "react-router-dom";

const Profile = () => {
  const navigation = useNavigation();
  const loadingClass = navigation.state === "loading" ? "loading" : "";

  return (
    <div
      id="app-profile"
      className={
        "pt-36 flex justify-center content-center w-full " + loadingClass
      }
    >
      <Card className="w-3/5 min-w-[600px] h-[445px] deepdive-loading shadow-lg">
        <Tabs className="w-full h-[410px]" tabPosition="left" size="large">
          <Tabs.TabPane tab="Profile" key="profile">
            <div className="w-full h-[410px]">
              <ProfileTab />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Experiments" key="experiments">
            <div className="w-full">
              <ExperimentsTab />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default Profile;
