import { Typography } from "antd";
import CreateDatabaseCard from "components/app/onboarding/create_database_card";
import CreateExperimentCard from "components/app/onboarding/create_experiment_card";
import Logo from "components/home/logo";
import { useState } from "react";

const CreateExperiment = () => {
  const [databaseId, setDatabaseId] = useState(null);

  return (
    <div className="pt-32 flex justify-center content-center w-full">
      <div className="flex-col w-1/3">
        <div>
          <div className="flex justify-center content-center">
            <Logo />
          </div>
          <div className="pb-2" />
          <Typography.Title level={3}>Welcome back.</Typography.Title>
        </div>
        <div className="w-full">
          {databaseId ? (
            <CreateExperimentCard databaseId={databaseId} />
          ) : (
            <CreateDatabaseCard setDatabaseId={setDatabaseId} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateExperiment;
