import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";

const DEFAULT_TRANSFORM = {
  type: "add_column",
};

const AddTransformButton = ({ setActiveTransform }) => {
  return (
    <Button
      icon={<PlusOutlined />}
      onClick={() => setActiveTransform(DEFAULT_TRANSFORM)}
    >
      Add
    </Button>
  );
};

export default AddTransformButton;
