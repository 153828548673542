import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import DashboardViz from "components/app/dashboard/dashboard_viz";
import useExperimentContext from "hooks/use_experiment_context";
import useMessage from "hooks/use_message";
import { unauthAxios } from "http/axios";
import fileDownload from "js-file-download";

const DownloadExperimentButton = ({ experiment }) => {
  const message = useMessage();

  const downloadExperiment = () => {
    message.info("Download started");
    unauthAxios
      .get(`export_shared_experiment/${experiment.id}/`, {
        responseType: "blob",
      })
      .then((response) => {
        fileDownload(response.data, "experiment.xlsx");
        message.success("Download succeeded");
      })
      .catch(() => {
        message.error("Download failed");
      });
  };

  return (
    <div className="flex justify-center content-center">
      <Button
        type="text"
        size="small"
        icon={<DownloadOutlined />}
        onClick={downloadExperiment}
      >
        Download experiment
      </Button>
    </div>
  );
};

const DashboardHeader = ({ experiment }) => {
  return (
    <>
      <div className="flex justify-center content-center">
        <p className="text-2xl font-semibold">{experiment.name}</p>
      </div>
      <DownloadExperimentButton experiment={experiment} />
    </>
  );
};

const DashboardGrid = ({ visualizations }) => (
  <div className="grid grid-cols-1 gap-4 pb-4">
    {visualizations.map((viz) => (
      <DashboardViz key={viz.id} viz={viz} />
    ))}
  </div>
);

const DashboardView = () => {
  const { experiment, visualizations } = useExperimentContext();

  return (
    <>
      <div className="pt-8 flex justify-center content-center w-full">
        <div className="flex-col w-4/5">
          <DashboardHeader experiment={experiment} />
          <div className="pb-4" />
          <DashboardGrid visualizations={visualizations} />
        </div>
      </div>
    </>
  );
};

export default DashboardView;
