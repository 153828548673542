import { getReadableMessage } from "components/app/transforms/transform_types";
import { DEEPDIVE_SERVER_WS } from "constants";
import useMessage from "hooks/use_message";
import { useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

const sendPrompt = (sendJsonMessage, dispatch) => {
  return (prompt) => {
    dispatch({ type: "set_processing_prompt", processing: true });
    sendJsonMessage({ action: "process_prompt", prompt: prompt });
  };
};

const processPrompt = (response, dispatch) => {
  dispatch({ type: "add_viz", viz: response.data });
  dispatch({ type: "set_active_viz", viz: response.data });
  dispatch({ type: "set_editing_viz", editing: true });
  dispatch({ type: "set_processing_prompt", processing: false });
};

const sendAddViz = (sendJsonMessage, dispatch) => {
  return () => {
    sendJsonMessage({ action: "add_viz" });
  };
};

const processAddViz = (response, dispatch) => {
  dispatch({ type: "add_viz", viz: response.data });
  dispatch({ type: "set_active_viz", viz: response.data });
  dispatch({ type: "set_editing_viz", editing: true });
};

const sendRemoveViz = (sendJsonMessage, dispatch) => {
  return (id) => {
    dispatch({ type: "remove_viz", id: id });
    sendJsonMessage({
      action: "remove_viz",
      viz_id: id,
    });
  };
};

const processRemoveViz = (response, dispatch) => {
  return;
};

const sendPreviewViz = (sendJsonMessage, dispatch) => {
  return (vizId, vizSpec) => {
    sendJsonMessage({
      action: "preview_viz",
      viz_id: vizId,
      viz_spec: vizSpec,
    });
  };
};

const processPreviewViz = (response, dispatch) => {
  dispatch({ type: "set_active_viz", viz: response.data });
};

const sendCommitViz = (sendJsonMessage, dispatch) => {
  return (viz) => {
    sendJsonMessage({
      action: "commit_viz",
      viz_id: viz.id,
      viz: JSON.stringify(viz),
    });
  };
};

const processCommitViz = (response, dispatch) => {
  dispatch({ type: "set_viz", viz: response.data });
  dispatch({ type: "set_editing_viz", editing: false });
};

const sendGetTable = (sendJsonMessage, dispatch) => {
  return () => {
    sendJsonMessage({
      action: "get_table",
    });
  };
};

const processGetTable = (response, dispatch) => {
  dispatch({ type: "set_reloading_data", reloading: false });
  dispatch({ type: "set_table", table: response.data });
};

const sendAddTransform = (sendJsonMessage, dispatch) => {
  return (transformSpec) => {
    dispatch({ type: "set_reloading_data", reloading: true });
    sendJsonMessage({
      action: "add_transform",
      transform_spec: transformSpec,
    });
  };
};

const processAddTransform = (response, dispatch, message) => {
  dispatch({ type: "set_reloading_data", reloading: false });
  if (response["status"] === 400) {
    message.error(
      `Transform failed: ${JSON.stringify(response.error_message)}`,
    );
    return;
  }

  const transform = JSON.parse(response.data.transform_spec);
  dispatch({ type: "set_table", table: response.data.table });
  dispatch({
    type: "set_db_schema",
    dbSchema: JSON.parse(response.data.db_schema),
  });
  dispatch({
    type: "add_transform",
    transform: transform,
  });
  message.success(getReadableMessage(transform));
};

const sendEditTransform = (sendJsonMessage, dispatch) => {
  return (transformIndex, transformSpec) => {
    dispatch({ type: "set_reloading_data", reloading: true });
    sendJsonMessage({
      action: "edit_transform",
      transform_index: transformIndex,
      transform_spec: transformSpec,
    });
  };
};

const processEditTransform = (response, dispatch, message) => {
  dispatch({ type: "set_reloading_data", reloading: false });
  if (response["status"] === 400) {
    message.error(
      `Transform failed: ${JSON.stringify(response.error_message)}`,
    );
    return;
  }

  const transform = JSON.parse(response.data.transform_spec);
  dispatch({ type: "set_table", table: response.data.table });
  dispatch({
    type: "set_db_schema",
    dbSchema: JSON.parse(response.data.db_schema),
  });
  dispatch({
    type: "set_transform",
    index: response.data.transform_index,
    transform: JSON.parse(response.data.transform_spec),
  });
  message.success(getReadableMessage(transform));
};

const sendRemoveTransform = (sendJsonMessage, dispatch) => {
  return (transformIndex) => {
    dispatch({ type: "set_reloading_data", reloading: true });
    sendJsonMessage({
      action: "remove_transform",
      transform_index: transformIndex,
    });
  };
};

const processRemoveTransform = (response, dispatch, message) => {
  dispatch({ type: "set_reloading_data", reloading: false });
  if (response["status"] === 400) {
    message.error(
      `Failed to remove transform: ${JSON.stringify(response.error_message)}`,
    );
    return;
  }

  dispatch({ type: "set_table", table: response.data.table });
  dispatch({
    type: "set_db_schema",
    dbSchema: JSON.parse(response.data.db_schema),
  });
  dispatch({
    type: "remove_transform",
    index: response.data.transform_index,
  });
  message.success("Transform removed");
};

const processAction = (response, dispatch, message) => {
  const action = response["action"];
  if (action === "process_prompt") {
    processPrompt(response, dispatch);
  } else if (action === "add_viz") {
    processAddViz(response, dispatch);
  } else if (action === "remove_viz") {
    processRemoveViz(response, dispatch);
  } else if (action === "preview_viz") {
    processPreviewViz(response, dispatch);
  } else if (action === "commit_viz") {
    processCommitViz(response, dispatch);
  } else if (action === "add_transform") {
    processAddTransform(response, dispatch, message);
  } else if (action === "edit_transform") {
    processEditTransform(response, dispatch, message);
  } else if (action === "remove_transform") {
    processRemoveTransform(response, dispatch, message);
  } else if (action === "get_table") {
    processGetTable(response, dispatch);
  }
};

export const useExperimentWebsocket = (id, dispatch) => {
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    `${DEEPDIVE_SERVER_WS}/experiments/${id}/`,
    {
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      share: true,
    },
  );
  const message = useMessage();

  useEffect(() => {
    lastJsonMessage && processAction(lastJsonMessage, dispatch, message);
  }, [lastJsonMessage, dispatch, message]);

  return {
    wsLoading: readyState === ReadyState.CONNECTING,
    wsActions: {
      processPrompt: sendPrompt(sendJsonMessage, dispatch),
      addViz: sendAddViz(sendJsonMessage, dispatch),
      removeViz: sendRemoveViz(sendJsonMessage, dispatch),
      previewViz: sendPreviewViz(sendJsonMessage, dispatch),
      commitViz: sendCommitViz(sendJsonMessage, dispatch),
      addTransform: sendAddTransform(sendJsonMessage, dispatch),
      editTransform: sendEditTransform(sendJsonMessage, dispatch),
      removeTransform: sendRemoveTransform(sendJsonMessage, dispatch),
      getTable: sendGetTable(sendJsonMessage, dispatch),
    },
  };
};
