import { Button, Modal } from "antd";
import DashboardVizTitle from "components/app/dashboard/dashboard_viz_title";
import VizWrapper from "components/viz/viz_wrapper";
import useExperimentContext from "hooks/use_experiment_context";
import useMessage from "hooks/use_message";
import { useEffect, useState } from "react";

const DashboardVizEditModal = () => {
  const { activeViz, setActiveViz, editingViz, setEditingViz, commitViz } =
    useExperimentContext();
  const [saving, setSaving] = useState(false);
  const message = useMessage();

  useEffect(() => {
    if (saving && !editingViz) {
      message.success("Viz saved");
      setSaving(false);
    }
  }, [message, saving, setSaving, editingViz, setEditingViz]);

  const onOk = () => {
    setSaving(true);
    commitViz(activeViz);
  };

  return (
    <Modal
      title="Edit visualization"
      open={editingViz}
      onCancel={() => setEditingViz(false)}
      onOk={onOk}
      transitionName=""
      width={1200}
      footer={[
        <Button type="primary" loading={saving} onClick={onOk}>
          Save
        </Button>,
      ]}
    >
      <DashboardVizTitle viz={activeViz} setViz={setActiveViz} />
      <VizWrapper mode="edit" viz={activeViz} key={activeViz.timestamp} />
    </Modal>
  );
};

export default DashboardVizEditModal;
