import { QuestionCircleOutlined } from "@ant-design/icons";
import { Input, Tooltip } from "antd";
import InlinePythonEditor from "components/app/transforms/editor/inline_python_editor";

const ColumnNameEditor = ({ name, setName }) => {
  return (
    <>
      <div className="flex items-center">
        <p className="text-sm font-normal">Column name</p>
        <Tooltip title="The name of the column to be added">
          <div className="ml-auto">
            <QuestionCircleOutlined />
          </div>
        </Tooltip>
      </div>
      <Input
        placeholder="name..."
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </>
  );
};

const ColumnExpressionEditor = ({ expression, setExpression }) => {
  return (
    <>
      <div className="flex">
        <div className="text-sm font-normal">Python expression</div>
        <div className="ml-auto">
          <Tooltip title="Any simple Python expression is supported. e.g, `Luminance` * 100. Note that you MUST wrap column names in backticks, e.g, `Luminance`">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      </div>
      <InlinePythonEditor
        name="add-column-editor"
        value={expression}
        setValue={setExpression}
      />
    </>
  );
};

const AddColumnEditor = ({ transform, setTransform }) => {
  return (
    <>
      <ColumnNameEditor
        name={transform.name}
        setName={(name) => setTransform({ ...transform, name: name })}
      />
      <div className="pb-2" />
      <ColumnExpressionEditor
        expression={transform.expression}
        setExpression={(expression) =>
          setTransform({ ...transform, expression: expression })
        }
      />
    </>
  );
};

export default AddColumnEditor;
