import ExperimentView from "components/app/experiment/experiment_view";
import { unauthAxios } from "http/axios";
import { useLoaderData } from "react-router-dom";

export async function loader({ params }) {
  const { data } = await unauthAxios.get(`/share/${params.id}`);
  return data;
}

const SharedExperiment = () => {
  const experiment = useLoaderData();
  return (
    <div>
      <ExperimentView key={experiment.id} experiment={experiment} />
    </div>
  );
};

export default SharedExperiment;
