import { AutoComplete, Input } from "antd";
import useExperimentContext from "hooks/use_experiment_context";
import { useState } from "react";

const AddVizInput = () => {
  const [input, setInput] = useState("");
  const { processPrompt, processingPrompt, wsLoading } = useExperimentContext();
  const addVizPrompt = (value) => {
    if (value) {
      processPrompt(value);
      setInput("");
    }
  };

  return (
    <div
      style={{
        marginTop: 12,
        paddingBottom: 12,
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 50,
      }}
    >
      <div className="flex justify-center items-center w-full">
        <div className="flex flex-col w-1/2">
          <AutoComplete
            autoFocus={true}
            style={{ width: "100%" }}
            open={input.startsWith("/")}
            value={input}
          >
            <Input.Search
              size="large"
              autoFocus={true}
              enterButton
              placeholder="Ask..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onSearch={(value, _) => addVizPrompt(value)}
              loading={wsLoading || processingPrompt}
            />
          </AutoComplete>
        </div>
      </div>
    </div>
  );
};

export default AddVizInput;
