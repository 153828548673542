import { EditOutlined } from "@ant-design/icons";
import { Button, Input, Spin, Tooltip } from "antd";
import DashboardActions from "components/app/dashboard/dashboard_actions";
import ShareExperimentButton from "components/app/experiment/share_experiment_button";
import SheetActions from "components/app/sheet/sheet_actions";
import useExperimentContext from "hooks/use_experiment_context";
import useMessage from "hooks/use_message";
import { authAxios } from "http/axios";
import { useState } from "react";

const ExperimentNameView = ({ name, setEditing }) => {
  return (
    <div className="flex items-start space-x-1 pt-2">
      <p className="text-lg font-normal">
        {name}
        <Tooltip title="Edit experiment name">
          <Button
            size="large"
            type="text"
            icon={<EditOutlined />}
            onClick={() => setEditing(true)}
          />
        </Tooltip>
      </p>
    </div>
  );
};

const ExperimentNameEdit = ({ experimentId, name, setName, setEditing }) => {
  const [updating, setUpdating] = useState(false);
  const message = useMessage();

  const updateName = (name) => {
    setUpdating(true);
    authAxios
      .patch(`/experiments/${experimentId}/`, { name: name })
      .then(() => {
        setUpdating(false);
        setEditing(false);
        message.success("Updated experiment name");
      })
      .catch(() => {
        setUpdating(false);
        setEditing(false);
        message.error("Failed to update experiment name, try again");
      });
  };

  return (
    <div className="py-1 flex items-center space-x-1">
      {updating ? (
        <Spin size="large" />
      ) : (
        <>
          <Input
            autoSize
            className="text-lg font-normal w-full"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onPressEnter={() => updateName(name)}
          />
          <div>
            <Button
              className="mx-0"
              size="large"
              type="primary"
              onClick={() => updateName(name)}
            >
              Ok
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const ExperimentHeader = () => {
  const { experiment, activeTab } = useExperimentContext();
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(experiment.name);

  return (
    <>
      <div className="grid grid-cols-2 justify-items-stretch">
        <div className="justify-self-start w-full">
          {editing ? (
            <ExperimentNameEdit
              experimentId={experiment.id}
              name={name}
              setName={setName}
              setEditing={setEditing}
            />
          ) : (
            <ExperimentNameView name={name} setEditing={setEditing} />
          )}
        </div>
        <div className="justify-self-end self-center text-xs font-medium">
          <div className="flex flex-row flex-nowrap justify-end gap-2">
            {activeTab === "dashboard" ? (
              <DashboardActions />
            ) : (
              <SheetActions />
            )}
            <ShareExperimentButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExperimentHeader;
