import { Tabs } from "antd";
import DashboardEditor from "components/app/dashboard/dashboard_editor";
import ExperimentHeader from "components/app/experiment/experiment_header";
import { getTransforms } from "components/app/experiment/helper";
import SheetEditor from "components/app/sheet/sheet_editor";
import { ExperimentContext } from "hooks/use_experiment_context";
import { useExperimentWebsocket } from "hooks/use_experiment_websocket";
import update from "immutability-helper";
import { useReducer } from "react";

const reducer = (state, action) => {
  switch (action.type) {
    case "add_viz": {
      return {
        ...state,
        visualizations: [...state.visualizations, action.viz],
      };
    }
    case "set_viz": {
      const index = state.visualizations.findIndex(
        (viz) => viz.id === action.viz.id,
      );
      return {
        ...state,
        visualizations: update(state.visualizations, {
          [index]: { $set: action.viz },
        }),
      };
    }
    case "remove_viz": {
      return {
        ...state,
        visualizations: state.visualizations.filter(
          (viz) => viz.id !== action.id,
        ),
      };
    }
    case "add_transform": {
      return {
        ...state,
        transforms: [...state.transforms, action.transform],
        activeTransform: {
          ...action.transform,
          index: state.transforms.length,
        },
      };
    }
    case "set_transform": {
      return {
        ...state,
        transforms: update(state.transforms, {
          [action.index]: { $set: action.transform },
        }),
      };
    }
    case "remove_transform": {
      return {
        ...state,
        transforms: update(state.transforms, { $splice: [[action.index, 1]] }),
      };
    }
    case "set_active_viz": {
      return {
        ...state,
        activeViz: action.viz,
      };
    }
    case "set_active_transform": {
      return {
        ...state,
        activeTransform: action.transform,
      };
    }
    case "set_editing_viz": {
      return {
        ...state,
        editingViz: action.editing,
      };
    }
    case "set_table": {
      return {
        ...state,
        table: action.table,
      };
    }
    case "set_db_schema": {
      return {
        ...state,
        dbSchema: action.dbSchema,
      };
    }
    case "set_reloading_data": {
      return {
        ...state,
        reloadingData: action.reloading,
      };
    }
    case "set_active_tab": {
      return {
        ...state,
        activeTab: action.tab,
      };
    }
    case "set_processing_prompt": {
      return {
        ...state,
        processingPrompt: action.processing,
      };
    }
    default:
      throw Error("Unknown action: " + action.type);
  }
};

const ExperimentEditor = ({ experiment }) => {
  const [state, dispatch] = useReducer(reducer, {
    table: null,
    dbSchema: JSON.parse(experiment.db_schema),
    visualizations: experiment.dashboard.visualizations,
    transforms: getTransforms(experiment),
    activeViz: {},
    editingViz: false,
    activeTransform: {},
    reloadingData: true,
    processingPrompt: false,
    activeTab: "dashboard",
  });
  const { wsLoading, wsActions } = useExperimentWebsocket(
    experiment.id,
    dispatch,
  );
  const actions = {
    setActiveViz: (viz) => dispatch({ type: "set_active_viz", viz: viz }),
    setEditingViz: (editing) =>
      dispatch({ type: "set_editing_viz", editing: editing }),
    setActiveTransform: (transform) =>
      dispatch({ type: "set_active_transform", transform: transform }),
    ...wsActions,
  };

  return (
    <ExperimentContext.Provider
      value={{
        mode: "edit",
        ...state,
        ...actions,
        wsLoading,
        experiment,
      }}
    >
      <div className="flex justify-center content-center w-full">
        <div className="flex-col w-full px-4">
          <ExperimentHeader />
          <Tabs
            tabPosition="top"
            activeKey={state.activeTab}
            tabBarGutter={16}
            onChange={(key) => dispatch({ type: "set_active_tab", tab: key })}
            items={[
              {
                label: (
                  <span className="text-sm font-normal mx-0 px-0 pt-0 mt-0">
                    Dashboard
                  </span>
                ),
                key: "dashboard",
                children: <DashboardEditor />,
              },
              {
                label: (
                  <span className="text-sm font-normal mx-0 px-0">Sheet</span>
                ),
                key: "sheet",
                children: <SheetEditor />,
              },
            ]}
          />
        </div>
      </div>
    </ExperimentContext.Provider>
  );
};

export default ExperimentEditor;
