import ExperimentEditor from "components/app/experiment/experiment_editor";
import { authAxios } from "http/axios";
import { useLoaderData } from "react-router-dom";

export async function loader({ params }) {
  const { data } = await authAxios.get(`/experiments/${params.id}`);
  return data;
}

const Experiment = () => {
  const experiment = useLoaderData();
  return (
    <div>
      <ExperimentEditor key={experiment.id} experiment={experiment} />
    </div>
  );
};

export default Experiment;
