import { Divider } from "antd";

const FAQ = () => {
  return (
    <div className="pt-24 flex justify-center content-center w-full">
      <div className="flex-col w-3/5">
        <div className="flex justify-center content-center">
          <p className="text-3xl font-normal">FAQ</p>
        </div>
        <Divider />
        <div className="pb-6" />
        <div className="flex flex-col justify-center content-center">
          <p className="text-base font-bold">What is DeepDive?</p>
          <p className="text-base font-normal pb-8">
            DeepDive is a tool for data analysis and visualization. <br />
            It takes questions, translates them to SQL, executes them and
            visualizes the result. See our demo{" "}
            <a
              href="https://www.youtube.com/watch?v=mZ9mRn0zGo8"
              className="text-blue-600 hover:text-blue-800"
            >
              here.
            </a>
          </p>

          <p className="text-base font-bold">How does DeepDive work?</p>
          <p className="text-base font-normal pb-8">
            DeepDive uses natural language models to translate questions to SQL.{" "}
            <br />
            DeepDive post-processes and executes translated SQL queries.
            Resulting data is rendered as visualizations, which users can edit
            through DeepDive's UIs. Edits to visualizations modify and
            re-execute underlying SQL queries.
          </p>

          <p className="text-base font-bold">
            What data does DeepDive support?
          </p>
          <p className="text-base font-normal pb-8">
            DeepDive supports Excel and CSV files up to 50MB. <br />
            DeepDive does support other SQL databases (e.g, Snowflake /
            Postgres), but they are not publicly available as of now. <br />
            If you're interested, please drop us a note at{" "}
            <a
              href="mailto:dev@bkdevs.io"
              className="text-blue-600 hover:text-blue-800"
            >
              dev@bkdevs.io
            </a>
          </p>

          <p className="text-base font-bold">
            How does DeepDive store my files?
          </p>
          <p className="text-base font-normal pb-8">
            DeepDive stores files encrypted at rest in S3.
          </p>

          <p className="text-base font-bold">
            How does DeepDive process my data?
          </p>
          <p className="text-base font-normal pb-8">
            DeepDive loads files from S3 on session load. <br />A temporary SQL
            database is then created and used for the session. This database is
            deleted on disconnect (i.e, tab closed, user navigated away). Your
            files are not persisted in any intermediate form otherwise.
          </p>

          <p className="text-base font-bold">What models does DeepDive use?</p>
          <p className="text-base font-normal pb-8">
            DeepDive is currently using OpenAI GPT-3.5 <br />
            If you're interested in using GPT-4 or alternative LLMs, let us
            know:{" "}
            <a
              href="mailto:dev@bkdevs.io"
              className="text-blue-600 hover:text-blue-800"
            >
              dev@bkdevs.io
            </a>
          </p>

          <p className="text-base font-bold">
            What data does DeepDive send to OpenAI?
          </p>
          <p className="text-base font-normal pb-8">
            DeepDive sends table metadata to OpenAI to generate SQL queries.
            This comprises of table names and column names. <br />
            We do not send your data to OpenAI.
          </p>

          <p className="text-base font-bold">Who built DeepDive?</p>
          <p className="text-base font-normal pb-8">
            <a href="/about" className="text-blue-600 hover:text-blue-800">
              We did!
            </a>
          </p>
        </div>
        <div className="pb-48" />
      </div>
    </div>
  );
};

export default FAQ;
