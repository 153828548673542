import {
  PlusSquareOutlined,
  DeleteColumnOutlined,
  EditOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { Button, Select, Tooltip } from "antd";

// maps from schema.TransformType
const TRANSFORM_TYPES = [
  {
    value: "add_column",
    label: (
      <span className="flex space-x-1">
        <PlusSquareOutlined />
        <span className="inline text-sm font-medium">Add column</span>
      </span>
    ),
  },
  {
    value: "remove_column",
    label: (
      <span className="flex space-x-1">
        <DeleteColumnOutlined />
        <span className="inline text-sm font-medium">Remove column</span>
      </span>
    ),
  },
  {
    value: "rename_column",
    label: (
      <span className="flex space-x-1">
        <EditOutlined />
        <span className="inline text-sm font-medium">Rename column</span>
      </span>
    ),
  },
];

const TransformTypeSelector = ({ transform, setTransform }) => {
  return (
    <div className="flex space-x-1">
      <Select
        value={transform.type}
        className="w-5/6"
        options={TRANSFORM_TYPES}
        onChange={(value) => setTransform({ type: value })}
      />
      <div className="ml-auto text-sm">
        <Tooltip title="Go back">
          <Button
            type="text"
            icon={<RollbackOutlined />}
            onClick={() => setTransform({})}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default TransformTypeSelector;
