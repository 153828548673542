import { Button } from "antd";
import useExperimentContext from "hooks/use_experiment_context";
import useMessage from "hooks/use_message";

const SaveTransformButton = ({ validateTransform }) => {
  const { reloadingData, activeTransform, addTransform, editTransform } =
    useExperimentContext();
  const message = useMessage();

  const saveTransform = () => {
    const index = activeTransform.index;
    activeTransform.index = undefined; // don't send index back in transform_spec

    const validationError = validateTransform(activeTransform);
    if (validationError) {
      message.error(validationError);
      return;
    }

    index !== undefined
      ? editTransform(index, JSON.stringify(activeTransform))
      : addTransform(JSON.stringify(activeTransform));
  };

  return (
    <div className="flex flex-row-reverse">
      <Button type="primary" loading={reloadingData} onClick={saveTransform}>
        Save
      </Button>
    </div>
  );
};

export default SaveTransformButton;
