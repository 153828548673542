import { LinkedinFilled } from "@ant-design/icons";
import { Divider, Image } from "antd";
import Mikkel from "assets/mikkel.png";
import Paul from "assets/paul.png";

const About = () => {
  return (
    <div className="pt-24 flex justify-center content-center w-full">
      <div className="flex-col w-3/5">
        <div className="flex justify-center content-center">
          <p className="text-3xl font-normal">About us</p>
        </div>
        <Divider />
        <div className="flex flex-col justify-center content-center">
          <p className="text-base font-normal text-center">
            DeepDive was founded by two engineers with a love of data. <br />
            We both come from data driven companies and know how important data
            is, and how difficult it can be to analyze.
          </p>
        </div>
        <div className="pt-12 flex flex-row w-full items-center content-center justify-center space-x-16">
          <div className="flex flex-col w-1/3 items-center">
            <div className="border-8 border-zinc-200 rounded-lg border-solid">
              <Image src={Paul} width={300} height={375} preview={false} />
            </div>
            <div className="flex flex-col items-center pt-2">
              <p className="text-lg">Paul Bae</p>
              <p className="text-[16px] text-gray-600">
                Founder{" "}
                <a href="https://www.linkedin.com/in/pybae/">
                  <LinkedinFilled />
                </a>
              </p>
            </div>
          </div>
          <div className="flex flex-col w-1/3 items-center">
            <div className="border-8 border-zinc-200 rounded-lg border-solid">
              <Image src={Mikkel} width={300} height={375} preview={false} />
            </div>
            <div className="flex flex-col items-center pt-2">
              <p className="text-lg">Mikkel Kim</p>
              <p className="text-[16px] text-gray-600">
                Founder{" "}
                <a href="https://www.linkedin.com/in/mikkel-kim-488b88b3/">
                  <LinkedinFilled />
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="pb-48" />
      </div>
    </div>
  );
};

export default About;
