import { QuestionCircleOutlined } from "@ant-design/icons";
import { Input, Segmented, Select, Tooltip } from "antd";
import ChartEditorDrawer from "components/viz/editor/chart_editor_drawer";
import InlineSqlEditor from "components/viz/editor/inline_sql_editor";
import { useEffect, useState } from "react";

const COLUMN_TYPES = [
  { value: "field", label: "Field" },
  { value: "computed", label: "Computed column" },
];

const BreakdownComputed = ({ breakdown, setBreakdown }) => {
  return (
    <>
      <p className="text-sm font-normal">Name</p>
      <div className="pb-2">
        <Input
          placeholder="value..."
          value={breakdown.alias}
          onChange={(e) =>
            setBreakdown({ ...breakdown, alias: e.target.value })
          }
        />
      </div>
      <div className="flex">
        <div className="text-sm font-normal">Formula</div>
        <div className="ml-auto">
          <Tooltip title="Any valid SQL expression is supported. e.g, where field1 = 'foo' or field2 = 'bar'">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      </div>
      <div className="pb-2">
        <InlineSqlEditor
          name="yaxis-formula-sql-editor"
          value={breakdown.unparsed ? breakdown.name : ""}
          setValue={(value) =>
            setBreakdown({ ...breakdown, unparsed: true, name: value })
          }
        />
      </div>
    </>
  );
};

const BreakdownField = ({ breakdown, setBreakdown, axes }) => {
  return (
    <>
      <p className="text-sm font-normal">Column</p>
      <Select
        placeholder="Select column"
        size="middle"
        className="w-full pb-4"
        showSearch
        options={axes}
        value={breakdown.name}
        onChange={(value) => setBreakdown({ name: value })}
      />
    </>
  );
};

const BreakdownDrawer = ({ breakdown, setBreakdown, axes, open, onClose }) => {
  const [breakdownType, setBreakdownType] = useState("field");

  // reset which tab is active upon drawer close
  useEffect(() => {
    if (!open) {
      setBreakdownType(breakdown?.unparsed ? "computed" : "field");
    }
    // eslint-disable-next-line
  }, [open, setBreakdownType]);

  const content =
    breakdownType === "field" ? (
      <BreakdownField
        breakdown={breakdown}
        setBreakdown={setBreakdown}
        axes={axes}
      />
    ) : (
      <BreakdownComputed breakdown={breakdown} setBreakdown={setBreakdown} />
    );

  return (
    <ChartEditorDrawer title="Breakdown" open={open} onClose={onClose}>
      <p className="text-sm font-normal">Type</p>
      <Segmented
        size="default"
        options={COLUMN_TYPES}
        value={breakdownType}
        onChange={setBreakdownType}
      />
      <div className="pb-4" />
      {content}
    </ChartEditorDrawer>
  );
};

export default BreakdownDrawer;
