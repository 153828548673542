import { QuestionCircleOutlined } from "@ant-design/icons";
import { Select, Tooltip } from "antd";
import { getColumns } from "components/app/transforms/helper";
import useExperimentContext from "hooks/use_experiment_context";

const ColumnSelector = ({ column, setColumn }) => {
  const { dbSchema } = useExperimentContext();
  const columns = getColumns(dbSchema);

  return (
    <>
      <div className="flex items-center">
        <p className="text-sm font-normal">Column</p>
        <Tooltip title="The column to be removed">
          <div className="ml-auto">
            <QuestionCircleOutlined />
          </div>
        </Tooltip>
      </div>
      <Select
        placeholder="Select column"
        size="middle"
        className="w-full pb-4"
        showSearch
        options={columns}
        value={column}
        onChange={setColumn}
      />
    </>
  );
};

const RemoveColumnEditor = ({ transform, setTransform }) => {
  return (
    <>
      <ColumnSelector
        column={transform.column}
        setColumn={(column) => setTransform({ ...transform, column: column })}
      />
    </>
  );
};

export default RemoveColumnEditor;
