import { ShareAltOutlined } from "@ant-design/icons";
import { Button } from "antd";
import ShareExperimentModal from "components/app/experiment/share_experiment_modal";
import { useState } from "react";

const ShareExperimentButton = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        type="primary"
        icon={<ShareAltOutlined />}
        onClick={() => setModalOpen(true)}
      >
        Share
      </Button>
      <ShareExperimentModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
};

export default ShareExperimentButton;
