import AddColumnEditor from "components/app/transforms/editor/add_column_editor";

class AddColumnTransform {
  static renderEditor({ transform, setTransform }) {
    return (
      <AddColumnEditor transform={transform} setTransform={setTransform} />
    );
  }

  static getReadableName(transform) {
    return `Add column ${transform.name}`;
  }

  static getReadableMessage(transform) {
    return `Added column ${transform.name}: ${transform.expression}`;
  }

  static validateTransform(transform) {
    if (!transform.name) {
      return "Column name must be specified. Add to transform";
    } else if (!transform.expression) {
      return "Python expression must be specified. Add to transform";
    }
  }
}

export default AddColumnTransform;
