import { Input, Table, Tooltip } from "antd";
import { getSorter } from "components/app/helper";
import { useState } from "react";
import Highlighter from "react-highlight-words";
import moment from "moment";

const getHighlighter = (type, text, searchText) => {
  const inner = searchText ? (
    <Highlighter
      highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ""}
    />
  ) : (
    text
  );

  if (!shouldTruncate(type)) {
    return inner;
  } else {
    return (
      <Tooltip placement="topLeft" title={text}>
        {inner}
      </Tooltip>
    );
  }
};

const shouldTruncate = (type) => {
  return type === "string";
};

const getRender = (type, text, searchText) => {
  if (type === "datetime") {
    text = moment(text).format("MM-DD-YYYY");
  }
  return getHighlighter(type, text, searchText);
};

const getColumns = (schema, searchText) => {
  return schema?.fields
    .filter(({ name, _ }) => name !== "index")
    .map(({ name, type }) => ({
      title: name,
      dataIndex: name,
      width: Math.max(100, name.length * 10), // rough heuristic logic to get pixel length of title
      key: name,
      sorter: getSorter(name, type),
      render: (text) => getRender(type, text, searchText),
      ellipsis: shouldTruncate(type) && { showTitle: false },
    }));
};

const SheetTable = ({ table }) => {
  const [searchText, setSearchText] = useState(null);
  const { schema, data: rows } = JSON.parse(table || "{}");
  const columns = getColumns(schema, searchText);
  const filteredRows =
    searchText &&
    rows.filter((row) =>
      Object.keys(row).some((k) =>
        String(row[k]).toLowerCase().includes(searchText.toLowerCase()),
      ),
    );

  return (
    <div>
      {/* <Input.Search
        style={{ margin: "0 0 10px 0" }}
        placeholder="Search in table..."
        enterButton
        onSearch={(value) => setSearchText(value)}
      /> */}
      <Table
        bordered
        scroll={{ x: "100%", y: "calc(100vh - 250px)" }}
        dataSource={filteredRows ? filteredRows : rows}
        columns={columns}
        rowKey={"index"}
        sortDirections={["ascend", "descend"]}
        size={"small"}
        pagination={{ defaultPageSize: 50 }}
      />
    </div>
  );
};

export default SheetTable;
