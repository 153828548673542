import { Button, Card } from "antd";
import UploadDatabaseFile from "components/app/onboarding/upload_database_file";
import useMessage from "hooks/use_message";
import { authAxios } from "http/axios";
import { useState } from "react";

const CreateDatabaseCard = ({ setDatabaseId }) => {
  const [dbFiles, setDbFiles] = useState([]);
  const [creating, setCreating] = useState(false);
  const message = useMessage();

  const createDatabase = async () => {
    if (dbFiles.length === 0) {
      message.warning("Upload files to create an experiment");
      return;
    }

    setCreating(true);
    try {
      const { data } = await authAxios.post(
        "database/create_database/",
        dbFiles.map((dbFile) => dbFile.id),
      );
      setDatabaseId(data.id);
    } catch (error) {
      message.error(error.response.data);
    }
    setCreating(false);
  };

  return (
    <Card className="w-full shadow-lg">
      <div className="pb-4">
        <p className="text-sm font-normal">Upload files to get started</p>
      </div>
      <UploadDatabaseFile setDbFiles={setDbFiles} />
      <div className="pt-4 flex justify-center content-center">
        <Button loading={creating} size="large" onClick={createDatabase}>
          Create experiment
        </Button>
      </div>
    </Card>
  );
};

export default CreateDatabaseCard;
