export const getSetDomain = (spec, setSpec) => {
  return (left, right) => {
    if (spec?.x_axis) {
      setSpec({
        ...spec,
        x_axis: {
          ...spec.x_axis,
          domain: [left, right],
        },
      });
    }
  };
};
