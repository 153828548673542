import { Divider } from "antd";

const Privacy = () => {
  return (
    <div className="pt-24 flex justify-center content-center w-full">
      <div className="flex-col w-3/5">
        <div className="flex justify-center content-center">
          <p className="text-3xl font-normal">Privacy policy</p>
        </div>
        <div className="flex justify-center content-center">
          <p className="text-1xl font-normal">
            Last updated September 18, 2023
          </p>
        </div>
        <Divider />
        <div className="pb-6" />
        <div className="flex flex-col justify-center content-center">
          <p className="text-base font-normal pb-8">
            This privacy notice for DeepDive ("we", "us" or "our"), describes
            how and why we might collect, store, use, and/or share ("process")
            your information when you use our services ("Services"), such as
            when you:
            <ul className="list-disc pl-6">
              <li>
                Visit our website at{" "}
                <a
                  href="https://deepdiveapp.net"
                  className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                >
                  https://deepdiveapp.net
                </a>{" "}
                or any website of ours that links to this privacy notice
              </li>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
          </p>
          <p className="text-base font-normal pb-8">
            <span className="font-bold">Questions or concerns? </span>
            Reading this privacy notice will help you understand your privacy
            rights and choices. If you do not agree with our policies and
            practices, please do not use our Services. If you still have any
            questions or concerns, please contact us at{" "}
            <a
              href="mailto:support@bkdevs.io"
              className="text-blue-600 hover:text-blue-800"
            >
              support@bkdevs.io
            </a>
            .
          </p>
          <p className="text-xl font-bold pb-8">SUMMARY OF KEY POINTS</p>
          <p className="text-base font-normal pb-8">
            <span className="font-bold">
              What personal information do we process?{" "}
            </span>
            When you visit, use, or navigate our Services, we may process
            personal information depending on how you interact with us and the
            Services, the choices you make, and the products and features you
            use.
          </p>
          <p className="text-base font-normal pb-8">
            <span className="font-bold">
              Do we process any sensitive personal information?{" "}
            </span>
            We do not process sensitive personal information.
          </p>
          <p className="text-base font-normal pb-8">
            <span className="font-bold">
              How do we process your information?{" "}
            </span>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
            <ul className="list-disc pl-6">
              <li>
                <span className="font-bold">
                  To facilitate account creation and authentication and
                  otherwise manage user accounts.
                </span>{" "}
                We may process your information so you can create and log in to
                your account, as well as keep your account in working order.
              </li>
              <li>
                <span className="font-bold">To request feedback.</span> We may
                process your information when necessary to request feedback and
                to contact you about your use of our Services.
              </li>
              <li>
                <span className="font-bold">To improve model performance.</span>{" "}
                We may process your information to improve our Services.
              </li>
            </ul>
          </p>
          <p className="text-base font-normal pb-8">
            <span className="font-bold">
              How do we keep your information safe?{" "}
            </span>{" "}
            We have organizational and technical processes and procedures in
            place to protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information.
          </p>
        </div>
        <div className="pb-48" />
      </div>
    </div>
  );
};

export default Privacy;
