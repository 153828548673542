import RemoveColumnEditor from "components/app/transforms/editor/remove_column_editor";

class RemoveColumnTransform {
  static renderEditor({ transform, setTransform }) {
    return (
      <RemoveColumnEditor transform={transform} setTransform={setTransform} />
    );
  }

  static getReadableName(transform) {
    return `Remove column ${transform.column}`;
  }

  static getReadableMessage(transform) {
    return `Removed column ${transform.column}`;
  }

  static validateTransform(transform) {
    if (!transform.column) {
      return "Column must be specified. Add to transform";
    }
  }
}

export default RemoveColumnTransform;
