import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import useExperimentContext from "hooks/use_experiment_context";

const AddVizButton = () => {
  const { wsLoading, addViz } = useExperimentContext();

  return (
    <Button
      loading={wsLoading}
      icon={<PlusOutlined />}
      onClick={() => addViz()}
    >
      New viz
    </Button>
  );
};

export default AddVizButton;
