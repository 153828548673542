import { DeleteOutlined } from "@ant-design/icons";
import { Button, List } from "antd";
import AddTransformButton from "components/app/sheet/add_transform_button";
import getTransformer from "components/app/transforms/transform_types";
import useExperimentContext from "hooks/use_experiment_context";

const TransformListItem = ({ index, transform, setActiveTransform }) => {
  const { removeTransform } = useExperimentContext();
  const transformer = getTransformer(transform);

  return (
    <List.Item
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <div className="flex items-start w-full">
        <div className="w-auto">
          <Button
            type="link"
            className="text-xs px-0 py-0 text-inherit"
            style={{
              borderWidth: "0px 0px 0px 0px",
            }}
            onClick={() => setActiveTransform({ ...transform, index: index })}
          >
            <span className="truncate w-full">
              {transformer.getReadableName(transform)}
            </span>
          </Button>
        </div>
        <div className="w-1/12 ml-auto">
          <Button
            danger
            type="text"
            icon={<DeleteOutlined />}
            onClick={() => removeTransform(index)}
          />
        </div>
      </div>
    </List.Item>
  );
};

const TransformsList = () => {
  const { transforms, setActiveTransform } = useExperimentContext();
  return (
    <>
      <p className="pb-2 text-sm font-normal">Transforms</p>
      {transforms.length ? (
        <List
          size="large"
          className="w-full"
          dataSource={transforms}
          renderItem={(item, index) => (
            <TransformListItem
              index={index}
              transform={item}
              setActiveTransform={setActiveTransform}
            />
          )}
        />
      ) : (
        <div className="pt-2 flex w-full text-xs">No transforms configured</div>
      )}
      <div className="pt-2 flex justify-center content-center w-full">
        <div className="flex-col">
          <AddTransformButton setActiveTransform={setActiveTransform} />
        </div>
      </div>
    </>
  );
};

export default TransformsList;
