import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import DashboardVizEditModal from "components/app/dashboard/dashboard_viz_edit_modal";
import useExperimentContext from "hooks/use_experiment_context";
import useMessage from "hooks/use_message";
import { authAxios } from "http/axios";
import fileDownload from "js-file-download";

const DASHBOARD_VIZ_ACTIONS = [
  {
    label: (
      <span>
        <EditOutlined /> Edit
      </span>
    ),
    key: "edit_visualization",
  },
  {
    label: (
      <span>
        <ExportOutlined /> Export
      </span>
    ),
    key: "export_as_excel",
  },
  {
    label: (
      <span className="text-red-500">
        <DeleteOutlined /> Remove
      </span>
    ),
    key: "remove_report",
  },
];

const exportViz = (message, viz) => {
  message.info("Download started");
  authAxios
    .get(`export_visualization/${viz.id}/`, {
      responseType: "blob",
    })
    .then((response) => {
      fileDownload(response.data, "report.xlsx");
      message.success("Download succeeded");
    })
    .catch(() => {
      message.error("Download failed");
    });
};

const DashboardVizActions = ({ viz }) => {
  const { setActiveViz, setEditingViz, removeViz } = useExperimentContext();
  const message = useMessage();

  const processAction = (key) => {
    if (key === "edit_visualization") {
      setActiveViz(viz);
      setEditingViz(true);
    } else if (key === "export_as_excel") {
      exportViz(message, viz);
    } else if (key === "remove_report") {
      removeViz(viz.id);
      message.success("Viz removed");
    }
  };

  return (
    <>
      <Dropdown
        menu={{
          items: DASHBOARD_VIZ_ACTIONS,
          onClick: ({ key }) => processAction(key),
        }}
        trigger={["click"]}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
      >
        <Button type="text" icon={<EllipsisOutlined />} />
      </Dropdown>
      <DashboardVizEditModal />
    </>
  );
};

export default DashboardVizActions;
