import DashboardView from "components/app/dashboard/dashboard_view";
import { ExperimentContext } from "hooks/use_experiment_context";

const ExperimentView = ({ experiment }) => {
  return (
    <ExperimentContext.Provider
      value={{
        mode: "view",
        experiment,
        visualizations: experiment.dashboard.visualizations,
      }}
    >
      <DashboardView />
    </ExperimentContext.Provider>
  );
};

export default ExperimentView;
