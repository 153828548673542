import { Card, Col } from "antd";
import DashboardVizActions from "components/app/dashboard/dashboard_viz_actions";
import VizWrapper from "components/viz/viz_wrapper";
import useExperimentContext from "hooks/use_experiment_context";

const DashboardViz = ({ viz }) => {
  const { mode } = useExperimentContext();
  const actions = mode === "edit" && <DashboardVizActions viz={viz} />;

  return (
    <Col flex="auto">
      <Card
        title={viz.title}
        headStyle={{ paddingLeft: 10, paddingRight: 6 }}
        className="shadow-lg"
        extra={actions}
      >
        <VizWrapper mode="view" viz={viz} key={viz.timestamp} />
      </Card>
    </Col>
  );
};

export default DashboardViz;
