import { Card, Divider, Empty, Spin } from "antd";
import { useState } from "react";

const GenerateDashboardCard = () => {
  const [generating, setGenerating] = useState(false);

  return (
    <Card className="w-full shadow-lg">
      {generating ? (
        <Spin size="large" tip="Generating dashboard...">
          <div className="h-40 w-40" />
        </Spin>
      ) : (
        <div>
          <Empty
            description={
              <div>
                <p className="text-base font-medium">Empty dashboard!</p>
                <Divider />
                <p>
                  Create a new visualization by asking questions or clicking the
                  button on the top right
                </p>
              </div>
            }
          ></Empty>
        </div>
      )}
    </Card>
  );
};

export default GenerateDashboardCard;
