export const getColumns = (databaseSchema) => {
  const axes = [];

  // note: disregards session tables
  for (const table of databaseSchema.tables) {
    for (const column of table.columns) {
      axes.push({
        value: column.name,
        label: column.name,
      });
    }
  }

  axes.sort((a, b) => a.label.localeCompare(b.label));
  return axes;
};
