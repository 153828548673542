import { Layout, Menu, message, theme } from "antd";
import { LogoDiv } from "components/home/logo";
import { createContext } from "react";
import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import { HEADER_HEIGHT } from "routes/app_layout_constants";
import useAuthStore from "store/auth_store";

export const ShareLayoutContext = createContext();

const buildMenuItems = () => {
  return (
    <>
      {/* https://stackoverflow.com/questions/32551291/in-css-flexbox-why-are-there-no-justify-items-and-justify-self-properties */}
      <Menu.Item key="home" style={{ marginRight: "auto", paddingTop: "8px" }}>
        <LogoDiv />
      </Menu.Item>
    </>
  );
};

const ShareLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const accessToken = useAuthStore((state) => state.accessToken);
  const navigate = useNavigate();
  const navigation = useNavigation();
  const [messageApi, contextHolder] = message.useMessage();

  const menuOnClick = async (item) => {
    if (item.key === "home" && accessToken) {
      navigate("/app");
    } else if (item.key === "home" && !accessToken) {
      navigate("/");
    }
  };

  return (
    <ShareLayoutContext.Provider
      value={{
        messageApi: messageApi,
      }}
    >
      {contextHolder}
      <Layout style={{ minHeight: "100vh" }} className="bg-white">
        <Layout.Header
          style={{
            display: "flex",
            width: "100%",
            paddingInlineStart: 0,
            paddingInlineEnd: 16,
            height: HEADER_HEIGHT,
            background: colorBgContainer,
            position: "sticky",
            top: 0,
            zIndex: 50,
          }}
        >
          <Menu
            theme="light"
            mode="horizontal"
            onClick={menuOnClick}
            style={{
              width: "100%",
              height: HEADER_HEIGHT,
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            {buildMenuItems()}
          </Menu>
        </Layout.Header>
        <div
          id="app-main"
          className={`bg-white ${
            navigation.state === "loading" ? "loading" : ""
          }`}
        >
          <Outlet />
        </div>
      </Layout>
    </ShareLayoutContext.Provider>
  );
};
export default ShareLayout;
