import { Card } from "antd";
import TransformEditor from "components/app/sheet/transform_editor";
import TransformsList from "components/app/sheet/transforms_list";
import { objectEmpty } from "components/viz/spec_helper";
import useExperimentContext from "hooks/use_experiment_context";

const TransformsCard = () => {
  const { activeTransform, wsLoading } = useExperimentContext();

  return (
    <Card className="w-full shadow-lg" loading={wsLoading}>
      {objectEmpty(activeTransform) ? <TransformsList /> : <TransformEditor />}
    </Card>
  );
};

export default TransformsCard;
