import { Divider, Button, Card, List } from "antd";
import useMessage from "hooks/use_message";
import { authAxios } from "http/axios";
import moment from "moment";
import { useExperimentsQuery } from "queries";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const PreviousExperiment = ({ experiment, createExperiment }) => {
  return (
    <List.Item>
      <div className="flex flex-col">
        <div>
          <Button
            type="link"
            className="text-sm px-0 py-0 text-inherit"
            style={{
              borderWidth: "0px 0px 0px 0px",
            }}
            onClick={() => createExperiment(experiment.id)}
          >
            {experiment.name}
          </Button>
        </div>
        <div className="font-subdued text-gray-400">
          {moment(experiment.timestamp).format("MMMM Do, h:mm a")}
        </div>
      </div>
    </List.Item>
  );
};

const PreviousExperiments = ({ createExperiment }) => {
  const { data, isLoading } = useExperimentsQuery();

  return (
    <div>
      <p className="pb-2 text-sm font-normal">
        Select a previous experiment to use as template
      </p>
      <List
        loading={isLoading}
        itemLayout="horizontal"
        dataSource={data.slice(0, 5)}
        renderItem={(experiment) => (
          <PreviousExperiment
            experiment={experiment}
            createExperiment={createExperiment}
          />
        )}
      />
    </div>
  );
};

const CreateExperimentCard = ({ databaseId }) => {
  const [creating, setCreating] = useState(false);
  const navigate = useNavigate();
  const message = useMessage();

  const createExperiment = async (experimentId) => {
    setCreating(true);
    try {
      const { data } = await authAxios.post("/experiments/", {
        databaseId: databaseId,
        experimentId: experimentId,
      });
      navigate(`experiments/${data.id}`);
    } catch (error) {
      message.error(error.response.data);
    }
    setCreating(false);
  };

  return (
    <Card className="w-full shadow-lg">
      <PreviousExperiments createExperiment={createExperiment} />
      <Divider>OR</Divider>
      <div className="flex justify-center content-center">
        <Button
          loading={creating}
          size="large"
          onClick={() => createExperiment(undefined)}
        >
          Create new
        </Button>
      </div>
    </Card>
  );
};

export default CreateExperimentCard;
