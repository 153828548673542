import RenameColumnEditor from "components/app/transforms/editor/rename_column_editor";

class RenameColumnTransform {
  static renderEditor({ transform, setTransform }) {
    return (
      <RenameColumnEditor transform={transform} setTransform={setTransform} />
    );
  }

  static getReadableName(transform) {
    return `Rename column ${transform.old_name} to ${transform.new_name}`;
  }

  static getReadableMessage(transform) {
    return `Renamed column ${transform.old_name} to ${transform.new_name}`;
  }

  static validateTransform(transform) {
    if (!transform.old_name) {
      return "Column must be specified. Add to transform";
    }
    if (!transform.new_name) {
      return "New column name must be specified. Add to transform";
    }
  }
}

export default RenameColumnTransform;
