import SortButton from "components/viz/buttons/sort_button";
import ZoomOutButton from "components/viz/buttons/zoom_out_button";
import { useContext } from "react";
import { VizWrapperContext } from "components/viz/viz_wrapper";

const ChartButtons = ({ spec, setSpec }) => {
  const { mode } = useContext(VizWrapperContext);
  if (mode !== "edit") {
    return;
  }

  return (
    <div className="flex flex-row flex-nowrap justify-end gap-1">
      <div className="w-18">
        <SortButton spec={spec} setSpec={setSpec} />
      </div>
      <div className="w-18">
        <ZoomOutButton spec={spec} setSpec={setSpec} />
      </div>
    </div>
  );
};

export default ChartButtons;
