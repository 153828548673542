import { resolveAlias } from "components/viz/views/chart_helper";
import Plot from "react-plotly.js";

const simpleScatter = (rows, spec) => {
  const xName = spec.x_axis.name;
  const yName = spec.y_axises[0].name;
  const xDataKey = resolveAlias(spec.x_axis);
  const yDataKey = resolveAlias(spec.y_axises[0]);
  const xValues = rows.map((row) => row[xDataKey]);
  const yValues = rows.map((row) => row[yDataKey]);

  const textValues = rows.map((row) => {
    return Object.entries(row)
      .filter(([key, _]) => ![xDataKey, yDataKey, "index"].includes(key))
      .map(([key, value]) => `${key}: ${value}`)
      .join("<br>");
  });

  return [
    {
      x: xValues,
      y: yValues,
      text: textValues,
      hovertemplate: `<b>${xName}: %{x}</b><br><b>${yName}: %{y}</b><br><br>%{text}<extra></extra>`,
      type: "scattergl",
      mode: "markers",
      marker: { size: 3 },
    },
  ];
};

const breakdownScatter = (rows, spec) => {
  const xDataKey = resolveAlias(spec.x_axis);
  const yDataKey = resolveAlias(spec.y_axises[0]);
  const breakdownKey = resolveAlias(spec.breakdowns[0]);
  const textDataKey =
    spec.y_axises.length > 1 && resolveAlias(spec.y_axises[1]);
  const scatters = {};

  for (const row of rows) {
    const breakdown = row[breakdownKey];
    if (!(breakdown in scatters)) {
      scatters[breakdown] = {
        x: [],
        y: [],
        text: [],
        name: `${breakdownKey} : ${breakdown}`,
        type: "scattergl",
        mode: "markers",
        marker: { size: 3 },
      };
    }
    const scatter = scatters[breakdown];
    scatter.x.push(row[xDataKey]);
    scatter.y.push(row[yDataKey]);
    if (textDataKey) {
      scatter.text.push(row[textDataKey]);
    }
  }

  return Object.values(scatters);
};

const ScatterChartView = ({ rows, spec }) => {
  const xDataKey = resolveAlias(spec.x_axis);
  const yDataKey = resolveAlias(spec.y_axises[0]);
  const scatter =
    spec.breakdowns.length > 0
      ? breakdownScatter(rows, spec)
      : simpleScatter(rows, spec);
  return (
    <Plot
      style={{
        width: "100%",
        height: "100%",
      }}
      data={scatter}
      layout={{
        autosize: true,
        margin: {
          t: 20,
          b: 80,
          l: 40,
          r: 0,
        },
        scattermode: spec.breakdowns.length > 0 && "group",
        showlegend: spec.breakdowns.length > 0,
        legend: {
          x: 1,
          xanchor: "right",
          y: 1,
        },
        xaxis: { title: xDataKey },
        yaxis: { title: yDataKey },
      }}
      config={{ responsive: true }}
      useResizeHandler={true}
    />
  );
};

export default ScatterChartView;
